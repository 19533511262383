import React, { useEffect } from 'react';
// import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/Layout';
import { navigate } from 'gatsby';
// export { default as Head } from '../components/Head';

// import { SEO } from "../components/seo"
const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en';
  }
  const lang =
    navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return 'en';

  return lang;
};

const introtext = {
  en: 'Welcome!',
  es: 'Bienvenido!',
};

function Page({ pageContext }) {
  // const page = pageContext;
  let urlLang = getRedirectLanguage();
  useEffect(() => {
    if (['en', 'es'].includes(urlLang)) {
      navigate(`/${urlLang}/`, { replace: true });
    } else {
      navigate(`/en/`, { replace: true });
    }
  }, []);
  return (
    <Layout>
      <>
        <div className="content container" id="loading">
          <div className="columns is-centered">
            <h2>{introtext[urlLang]}</h2>
          </div>
          <div className="columns is-centered options">
            <a href="/en" className="button">
              English
            </a>
            <a href="/es" className="button">
              Español
            </a>
          </div>
          {/* <MDXRenderer>{page.body}</MDXRenderer> */}
        </div>
      </>
    </Layout>
  );
}

export default Page;
